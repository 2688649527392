export const sanitizeInput = (body: unknown): unknown => {
  if (body instanceof FormData) {
    const sanitizedFormData = new FormData();

    // @ts-ignore
    for (const [key, value] of body.entries()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      sanitizedFormData.append(key, value instanceof Blob ? value : sanitizeInput(value));
    }

    return sanitizedFormData;
  }

  if (body instanceof Blob) {
    return body;
  }

  if (Array.isArray(body)) {
    return body.map(sanitizeInput);
  }

  if (typeof body === 'string') {
    // Parse the input as HTML and extract only text content
    const doc = new DOMParser().parseFromString(body, 'text/html');

    return doc.body.textContent || '';
  }

  if (typeof body === 'object' && body !== null) {
    // Recursively clean object properties
    return Object.fromEntries(Object.entries(body).map(([key, value]) => [key, sanitizeInput(value)]));
  }

  return body; // Return as-is for numbers, booleans, null, etc.
};
